<template>
  <div>
    <h2 class="border-bottom pb-2 mb-3">
      <i class="fa fa-bullseye"></i> Catchers Report
    </h2>
    <div class="row listing">
      <div class="col-12">
        <div v-if="!scaling" class="row mb-3">
          <div class="col-12">
            <div class="form-inline">
              <button type="button" class="btn btn-primary mr-2" @click="load">
                Refresh
              </button>
              <button
                type="button"
                class="btn btn-secondary mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'scale';
                "
              >
                Scale
              </button>
              <button
                type="button"
                class="btn btn-secondary mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'blacklist';
                "
              >
                Blacklist
              </button>
              <button
                type="button"
                class="btn btn-warning mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'flush';
                "
              >
                Flush
              </button>
              <button
                type="button"
                class="btn btn-success mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'git';
                "
              >
                Git
              </button>
              <button
                type="button"
                class="btn btn-primary mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'npm';
                "
              >
                Npm
              </button>
              <button
                type="button"
                class="btn btn-danger mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'restart';
                "
              >
                Restart
              </button>
              <button
                type="button"
                class="btn btn-danger mr-2"
                @click="
                  scaling = true;
                  scalingMode = 'delete';
                "
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div v-if="scaling" class="row mb-3">
          <div class="col-12">
            <div class="form-inline" v-show="!processing">
              <div class="form-group mr-1">
                <label class="mr-2 text-capitalize"
                  >{{ scalingMode }} bulk action</label
                >
                <input
                  type="number"
                  step="1"
                  min="5"
                  max="30"
                  v-model="scaleValue"
                  class="form-control mr-2"
                  v-if="scalingMode == 'scale'"
                />
                <label class="mr-2">Target catchers matching</label>
                <input
                  type="text"
                  v-model="scaleTargets"
                  class="form-control mr-2"
                />
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="scale"
                  v-if="scalingMode == 'scale'"
                >
                  Scale
                </button>
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="blacklist"
                  v-if="scalingMode == 'blacklist'"
                >
                  Blacklist
                </button>
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="flush"
                  v-if="scalingMode == 'flush'"
                >
                  Flush
                </button>
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="restart"
                  v-if="scalingMode == 'restart'"
                >
                  Restart
                </button>
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="git"
                  v-if="scalingMode == 'git'"
                >
                  Git
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2"
                  @click="npm"
                  v-if="scalingMode == 'npm'"
                >
                  Npm
                </button>
                <button
                  type="button"
                  class="btn btn-success mr-2"
                  @click="this.delete"
                  v-if="scalingMode == 'delete'"
                >
                  Delete
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mr-2"
                  @click="scaling = false"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div class="mb-3 text-center" v-show="processing">
              <h4>Processing Workers</h4>
              <div class="progress" style="height: 40px">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style="width: 100%"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!scaling" class="row mb-3">
          <div class="col-6">
            <div class="card">
              <div class="card-body text-center">
                <h4>{{ getWorkers.active || 0 }} Processing</h4>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="card">
              <div class="card-body text-center">
                <h4>{{ getWorkers.total || 0 }} Workers</h4>
              </div>
            </div>
          </div>
        </div>
          <!--Place component here--->
          <WorkerGraph/>
        <div
          class="row mb-3"
          v-if="!scaling && getWorkers && getWorkers.workers"
          style="background-color: white; margin: 2px"
        >
          <div class="col-12">
            <table class="table">
              <tr>
                <th>Fingerprint</th>
                <th>Type</th>
                <th>Address</th>
                <th>CPU</th>
                <th>Memory</th>
                <th>Utilization</th>
                <th>Uptime</th>
                <th>Health</th>
                <!-- <th>Checks</th> -->
                <th>Actions</th>
              </tr>
              <tbody>
                <tr v-for="(worker, index) in getWorkers.workers" :key="index">
                  <td>{{ worker.fingerprint ? worker.fingerprint : worker.mac || 'N/A'}}</td>
                  <td>{{ worker.type ? worker.type : worker.type || 'N/A'}}</td>
                  <td>
                    <a
                      :href="`http://${worker.ip}:${worker.port}/`"
                      target="_blank"
                      >{{ worker.ip }}:{{ worker.port }}</a
                    >
                  </td>
                  <td>{{ worker.stats ? worker.stats.load[0] : 0 }}%</td>
                  <td>
                    {{
                      worker.stats ?
                      Number(
                        worker.stats.memory.free / 1024 / 1024 / 1000
                      ).toFixed(0)
                       : 0
                    }}/{{
                      worker.stats ?
                      Number(
                        worker.stats.memory.total / 1024 / 1024 / 1000
                      ).toFixed(0)
                       : 0
                    }} GB
                  </td>
                  <td>{{ worker.workers || 0 }} of {{ worker.max || 0 }}</td>
                  <td>
                    {{
                      worker.stats ?
                      Math.floor(Number(worker.stats.uptime / 86400)).toFixed(0)
                       : 0
                    }}
                    days
                  </td>
                  <td>{{ worker.health ? worker.health : worker.health || 0}}</td>
                  <!-- <td>{{ worker.status ? worker.status : worker.status || 'N/A'}}</td> -->
                  <!-- <td>{{ worker.checks ? `${worker.checks}/${worker.checks}` : 'N/A'}}</td> -->
                  <td>
                    <!-- Button trigger modal -->
                    <b-button
                      class="btn btn-secondary btn-sm mr-1"
                      v-b-modal.modal-1
                      @click.prevent="singleView(worker)"
                      >View</b-button
                    >
                    <button
                      class="btn btn-secondary btn-sm mr-1"
                      @click.prevent="singleScale(worker)"
                    >
                      Scale
                    </button>
                    <button
                      class="btn btn-secondary btn-sm mr-1"
                      @click.prevent="singleBlacklist(worker.ip)"
                    >
                      Blacklist
                    </button>
                    <button
                      class="btn btn-warning btn-sm mr-1"
                      @click.prevent="singleFlush(worker.ip)"
                    >
                      Flush
                    </button>
                    <button
                      class="btn btn-success btn-sm mr-1"
                      @click.prevent="singleGit(worker.ip)"
                    >
                      Git
                    </button>
                    <button
                      class="btn btn-primary btn-sm mr-1"
                      @click.prevent="singleNpm(worker.ip)"
                    >
                      Npm
                    </button>
                    <button
                      class="btn btn-danger btn-sm mr-1"
                      @click.prevent="singleRestart(worker.ip)"
                    >
                      Restart
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      @click.prevent="singleDelete(worker.ip)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div>
      <b-modal
        id="modal-1"
        centered
        hide-backdrop
        hide-footer
        :title="`Pi:${item.ip} Active:${
          item.item ? item.item.active : 'N/A'
        } Processing:${item.item ? item.item.processing : 'N/A'}`"
      >
        <!-- <p class="my-4">{{item}}</p> -->
        <b-list-group v-if="item.item">
          <!-- <b-list-group-item>Host: {{item.host}} </b-list-group-item>
                    <b-list-group-item>Port: {{item.port}}</b-list-group-item> -->
          <b-list-group-item
            >Uptime:
            {{
              Math.floor(Number(item.item.stats.uptime / 86400)).toFixed(0)
            }}
            days</b-list-group-item
          >
          <b-list-group-item
            >Max Workers: {{ item.item.max }}</b-list-group-item
          >
          <b-list-group-item
            >Utilization Processing: {{ item.item.workers }}/{{
              item.item.max
            }}</b-list-group-item
          >
          <b-list-group-item
            >CPU: {{ item.item.stats.cpu[0].model }}</b-list-group-item
          >
          <b-list-group-item
            >CPU Memory:
            {{
              Number(item.item.stats.memory.free / 1024 / 1024 / 1000).toFixed(
                0
              )
            }}/{{
              Number(item.item.stats.memory.total / 1024 / 1024 / 1000).toFixed(
                0
              )
            }}GB</b-list-group-item
          >
          <b-list-group-item
            >CPU Count: {{ item.item.stats.cpu.length }}</b-list-group-item
          >
          <b-list-group-item
            v-for="(ites, index) in item.item.stats.cpu"
            :key="index"
            >CPU Speed {{ index + 1 }}: {{ item.item.stats.cpu[index].speed }},
            CPU Times: user:{{ item.item.stats.cpu[index].times.user }} sys:{{
              item.item.stats.cpu[index].times.sys
            }}
            idle:{{ item.item.stats.cpu[index].times.idle }}</b-list-group-item
          >
          <b-list-group-item
            v-for="(ites, index) in item.item.stats.load"
            :key="index"
            >CPU Load {{ index + 1 }}:
            {{ item.item.stats.load[index] }}</b-list-group-item
          >
          <!--<b-list-group-item :href="`${item.ip}:${item.port}`">Test connection</b-list-group-item>-->
        </b-list-group>
        <!-- <template #modal-footer>
                    <div class="w-100">
                    </div>
                </template> -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import WorkerGraph from '@/components/dashboard/catcher/workerGraph';

export default {
  name: "Catchers",
  props: ["user"],
  components: {WorkerGraph},
  data: function () {
    return {
      action: "",
      editor: false,
      item: { active: false, processing: false },
      refreshData: "off",
      workers: { active: 0, total: 1, workers: [] },
      preloader: false,
      scaling: false,
      scalingMode: "",
      scaleValue: 35,
      scaleTargets: "",
      eventCatcher: null,
      processing: false,
    };
  },
  created: function () {
    this.stream();
    this.$root.preloader = false;
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      //   console.log('Modal is about to be hidden', bvEvent, modalId)
      this.item && this.item.stream ? this.item.stream.close() : () => {};
      //   this.eventWorker = null;
      this.item = false;
      //   console.log(this.item, this.eventWorker);
    });
    this.$root.$on("bv::modal::show", () => {
      //   console.log('Modal is about to be shown', bvEvent, modalId)
      //   this.eventWorker = null;
      this.item = false;
      //   console.log(this.item);
    });
  },
  computed: {
    getWorkers(){
      let data = this.workers || { active: 0, total: 0, workers: [] }
      return data;
    },
  },
  methods: {
    reload: function () {
      this.load();
      
    },
    stream: function () {
      //Load the catchers
      // var request = {'apikey': this.$root.apikey};

      if (this.eventCatcher) {
          this.eventCatcher.close();
          this.eventCatcher = null;
      }
      //Register eventstream
      if (!this.eventCatcher) {
        this.eventCatcher = new EventSource(
          `${this.$root.serverUrl}/static/workerstream`,
          { withCredentials: false }
        );
        this.eventCatcher.onerror = (error) => {
          console.log(`eventCatcher.error`, error);
        };
        this.eventCatcher.onopen = (args) => {
          console.log(`eventCatcher.open`, args);
        };
        this.eventCatcher.onmessage = (event) => {
          try {
            const parsedData = JSON.parse(event.data);
            console.log('stream data: ',parsedData);
            this.workers = Object.assign(
              { active: 0, total: 0, workers: [] },
              parsedData
            );
            this.$forceUpdate();
          } catch (e) {
            console.log(`eventCatcher.catch: ${e.message}`);
          }
        };
      }
    },
    load: function () {
      //Load the catchers
      var request = { apikey: this.$root.apikey };

      // this.$root.preloader = true;

      return axios
        .get(`${this.$root.serverUrl}/static/workers`, { params: request })
        .then((resp) => {
          this.workers = resp.data || { active: 0, total: 0, workers: [] };
          this.$root.preloader = false;
        });
    },
    scale: function () {
      //Scale the workers on each catcher

      //this.$root.preloader = true;
      this.processing = true;
      let urls = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1)
            urls.push(
              `http://${worker.ip}:${worker.port}/scale/${worker.fingerprint}`
            );
        } else {
          urls.push(
            `http://${worker.ip}:${worker.port}/scale/${worker.fingerprint}`
          );
        }
      }

      axios
        .post(`${this.$root.serverUrl}/static/workers/scale`, {
          urls: urls,
          max: Number(this.scaleValue || 5),
        })
        .then((resp) => {
          console.log(`Scaling processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Scaling error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
        });
    },

    npm: function () {
      //Restart the workers on each catcher
      //this.$root.preloader = true;
      this.processing = true;
      let urls = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1)
            urls.push(
              `http://${worker.ip}:${worker.port}/npm/${worker.fingerprint}`
            );
        } else {
          urls.push(
            `http://${worker.ip}:${worker.port}/npm/${worker.fingerprint}`
          );
        }
      }

      axios
        .post(`${this.$root.serverUrl}/static/workers/npm`, {
          urls: urls,
        })
        .then((resp) => {
          console.log(`Restarting processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Restarting error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
          this.scaleTargets = null;
        });
    },
    restart: function () {
      //Restart the workers on each catcher
      //this.$root.preloader = true;
      this.processing = true;
      let urls = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1)
            urls.push(
              `http://${worker.ip}:${worker.port}/restart/${worker.fingerprint}`
            );
        } else {
          urls.push(
            `http://${worker.ip}:${worker.port}/restart/${worker.fingerprint}`
          );
        }
      }

      axios
        .post(`${this.$root.serverUrl}/static/workers/restart`, {
          urls: urls,
        })
        .then((resp) => {
          console.log(`Restarting processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Restarting error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
          this.scaleTargets = null;
        });
    },
    blacklist: function () {
      //Refresh the blacklist on each catcher
      //this.$root.preloader = true;
      this.processing = true;
      let urls = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1)
            urls.push(
              `http://${worker.ip}:${worker.port}/blacklist/${worker.fingerprint}`
            );
        } else {
          urls.push(
            `http://${worker.ip}:${worker.port}/blacklist/${worker.fingerprint}`
          );
        }
      }

      axios
        .post(`${this.$root.serverUrl}/static/workers/blacklist`, {
          urls: urls,
        })
        .then((resp) => {
          console.log(`Blacklist processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Blacklist error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    flush: function () {
      //Flush the workers on each catcher
      //this.$root.preloader = true;
      this.processing = true;
      let urls = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1)
            urls.push(
              `http://${worker.ip}:${worker.port}/flush/${worker.fingerprint}`
            );
        } else {
          urls.push(
            `http://${worker.ip}:${worker.port}/flush/${worker.fingerprint}`
          );
        }
      }

      axios
        .post(`${this.$root.serverUrl}/static/workers/flush`, { urls: urls })
        .then((resp) => {
          console.log(`Flush processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Flush error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    delete: function () {
      //Delete catchers

      //this.$root.preloader = true;
      this.processing = true;
      let ids = [];

      for (var i = 0; i < this.workers.workers.length; i++) {
        let worker = this.workers.workers[i];
        if (this.scaleTargets) {
          if (worker.ip.search(this.scaleTargets) !== -1) ids.push(worker._id);
        } else {
          ids.push(worker._id);
        }
      }

      axios({
        url: `${this.$root.serverUrl}/static/workers`,
        data: { ids: ids },
        responseType: "json",
        method: "DELETE",
      })
        .then((resp) => {
          console.log(`Delete processed`, resp);
          swal.fire({
            title: "Processed",
            text: "Your request was submitted for processing.",
            icon: "success",
          });
          this.load();
          this.scaling = false;
        })
        .catch((err) => {
          console.log(`Delete error: ${err.message}`, err.stack);
          swal.fire({ title: "Error", text: err.message, icon: "error" });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    singleScale(worker) {
      swal
        .fire({
          title: "Number of workers",
          input: "range",
          inputAttributes: {
            autocapitalize: "off",
            min: 1,
            max: worker.ip.match(/10\.111\.20/) ? 25 : 215,
            step: 1,
          },
          inputValue: worker.max,
          showCancelButton: true,
          confirmButtonText: "Scale",
          showLoaderOnConfirm: true,
          allowOutsideClick: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.scaleTargets = worker.ip;
            this.scaleValue = Number(result.value || 1);
            this.scale();
          }
        });
    },
    singleView(worker) {
      let work = Object.assign(
        { active: false, processing: false, stream: null },
        worker
      );
      //Register eventstream
      // if (!work.stream) {
      //   let lru =`http://${work.ip}:${work.port}/stats?fingerprint=${work.fingerprint}`.toString();
        let lru = `http://${work.ip}:${work.port}/stats`.toString();
      //   // console.log(`singleView:change Item eventWorker on ${lru}`);
      //   work.stream = new EventSource(lru, { withCredentials: true });
      //   work.stream.onerror = (error) => {
      //     lru = `http://${work.ip}:${work.port}/`.toString();
      //     console.log(`eventWorker.error on ${lru}`, error);
      //     this.item = this.singleGet(lru, work, worker);
      //
      //     console.log(
      //       `singleView:change Item eventWorker on ${lru}`,
      //       this.item
      //     );
      //     // this.item && this.item.stream ? this.item.stream.close():(()=>{});
      //     // this.item = false;
      //   };
      //   work.stream.onopen = (args) => {
      //     console.log(`eventWorker.open`, args);
      //
      //     this.item = false;
      //   };
      //   work.stream.onmessage = (event) => {
      //     try {
      //       const parsedData = JSON.parse(event.data);
      //       worker = Object.assign(worker, work);
      //       work.item = parsedData;
      //       this.item = worker;
      //       this.$forceUpdate();
      //     } catch (e) {
      //       console.log(`eventWorker.catch: ${e.message}`);
      //     }
      //   };
      // }

        axios
            .get(`${lru}`, {
                fingerprint:work.fingerprint,
                ip:work.ip
            })
            .then((resp) => {
                console.log(`View Stats processed`, resp);
                let item = resp && resp.data && resp.data.length > 0 ? resp.data:{};
                this.item = item;
            })
      // console.log(`singleView:done`);
    },
    singleFlush(ip) {
      this.scaleTargets = ip;
      this.flush();
    },
    async singleGet(lru, work, worker) {
      return await axios.get(lru).then((resp) => {
        let data = resp.data || { active: 0, total: 0, workers: [] };
        worker = Object.assign(worker, data);
        work.item = data;
        console.log(`eventWorker.get`, data);
        return data;
      });
    },
    singleNpm(ip) {
      this.scaleTargets = ip;
      this.npm();
    },
    singleBlacklist(ip) {
      this.scaleTargets = ip;
      this.blacklist();
    },
    singleRestart(ip) {
      swal
        .fire({
          title: "Restart",
          text: "Are you sure",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
        })
        .then((val) => {
          if (!val.isConfirmed) return;

          this.scaleTargets = ip;
          this.restart();
        });
    },
    singleDelete(ip) {
      swal
        .fire({
          title: "Delete",
          text: "Are you sure",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
        })
        .then((val) => {
          if (!val.isConfirmed) return;

          this.scaleTargets = ip;
          this.delete();
        });
    },
  },
};
</script>

<style scoped></style>
