<template>
    <div class="c0l-12  pb-5">
        <div class="card">
            <div class="card-header">
                <h6 class="mb-0 font-weight-bolder">Workers Graph</h6>
            </div>
            <div class="card-body">
                <div class="row ml-3 pl-2">
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">
                                    <b-form-datepicker @change="search" id="example-datepicker" v-model="start" class="mb-2"></b-form-datepicker>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">
                                    <b-form-timepicker v-model="tstart" id="timepicker-sm" locale="en" class="mb-2"></b-form-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group">
                                    <b-form-datepicker @change="search" id="example-datepicker1" v-model="end" class="mb-2"></b-form-datepicker>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group">
                                    <b-form-timepicker v-model="tend" id="timepicker-sm1" locale="en" class="mb-2"></b-form-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button @click="search" class="btn btn-success">Search</button>
                        <button style="margin-left:8px;" @click="exporting" class="btn btn-info">Export</button>
                    </div>

                </div>
                <div class="col-12">
                    <ejs-chart :tooltip='tooltip'  id="container" :title='title' :primaryXAxis='primaryXAxis'>
                        <e-series-collection>
                            <e-series :dataSource='seriesData' type='Spline' xName='created_ts' yName='active' name='Active'  width=2 :marker='marker'> </e-series>
                            <e-series :dataSource='seriesData' type='Spline' xName='created_ts' yName='total' name='Total'  width=2 :marker='marker'> </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { ChartPlugin, SplineSeries, Category,Tooltip } from "@syncfusion/ej2-vue-charts";
import moment from "moment"
import axios from "axios";
import swal from "sweetalert";
Vue.use(ChartPlugin);

export default {
    name: "WorkerGraph",
    data() {
        return {
            workerStream: null,
            tooltip: {
                enable: true
            },
            seriesData:[],
            primaryXAxis: {
                title: 'Date Time',
                valueType: 'Category'
            },
            marker: { visible: true, width: 10, height: 10 },
            title: " ",
            start: '',
            end: '',
            tstart: '',
            tend: '',
        };
    },
    provide: {
        chart: [SplineSeries, Category,Tooltip]
    },
    async created(){
        await this.search()
    },
    methods: {
        async search(){
            try{

                let url = `${this.$root.serverUrl}/static/stream/workers`
                if(this.start === '' && this.end === ''){
                    this.start = moment.utc().startOf('day').format('YYYY-MM-DD')
                    this.end = moment.utc().endOf('day').format('YYYY-MM-DD')
                    this.tstart = moment.utc().startOf('day').format('h:mm:ss')
                    this.tend = moment.utc().endOf('day').format('h:mm:ss')
                }
                axios.get(url, {params: {start: this.start, end: this.end,tend:this.tend,tstart: this.tstart}}).then(function(resp){
                    resp.data.data.forEach((el)=>{
                        el.created_ts = el.created_ts.substr(0,19).replace('T',' ')
                    });
                    this.seriesData = (resp.data && resp.data.data)? resp.data.data : null;
                }.bind(this));
            }catch (e) {
                console.log(e);
            }
        },
        async exporting(){
            try{

                let url = `${this.$root.serverUrl}/static/export/stream/workers`
                if(this.start === '' && this.end === ''){
                    this.start = moment.utc().startOf('day').format('YYYY-MM-DD')
                    this.end = moment.utc().endOf('day').format('YYYY-MM-DD')
                    this.tstart = moment.utc().startOf('day').format('h:mm:ss')
                    this.tend = moment.utc().endOf('day').format('h:mm:ss')
                }
                axios.get(url, {responseType: 'blob',params: {start: this.start, end: this.end,tend:this.tend,tstart: this.tstart}}).then(function(resp){
                    var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `worlerlogs.csv`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    this.$root.preloader = false
                }.bind(this)).catch((err)=>{
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            }catch (e) {
                console.log(e);
            }
        }
    }
};
</script>
<style>
#container {
    height: 350px;
}
</style>
